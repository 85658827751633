import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Logo from '../../ui/logo'
import Socials from '../../ui/socials'

import styles from './footer.module.scss'

const Footer = ({ className }) => {
  return (
    <footer className={clsx(styles.footer, className)}>
      <div className={styles.content}>
        <div className={styles.content_block}>
          <Logo className={styles.logo} />
          <p>
            Любая информация, представленная на данном сайте, носит исключительно информационный
            характер и ни при каких условиях не является публичной офертой, определяемой положением
            ст 437 ГК РФ.
          </p>
          <Socials items={['vk', 'wa', 'tg']} />
          <div className={styles.contacts}>
            <a href='tel:+74994041764'>+7 (499) 404-17-64</a>
            <a href='mailto:hotel@friendlyinn.ru'>hotel@friendlyinn.ru</a>
          </div>
        </div>
        <div className={clsx(styles.content_block, styles.content_menu)}>
          <div className={styles.menu_title}>
            <h3>Полезные ссылки</h3>
          </div>
          <ul>
            <li>
              <a
                href='https://ru.matterport.host/SdvV9Ua8B8j/?m=SdvV9Ua8B8j&ss=124&sr=-1.49,1.38'
                target='_blank'
                rel='noreferrer nofolow'
              >
                3D Тур
              </a>
            </li>
            <li>
              <Link to='/shares'>Акции</Link>
            </li>
            <li>
              <Link to='/gallery'>Фотографии</Link>
            </li>
            <li>
              <Link to='/funny'>Развлечения</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to='/contacts'>Контакты</Link>
            </li>
            <li>
              <a
                href='https://russeasonshotel.ru/'
                target='_blank'
                rel='noreferrer nofolow'
              >
                Отель Regul
              </a>
            </li>
            <li>
              <Link to='/events'>Мероприятия</Link>
            </li>
            <li>
              <Link to='/loyalty'>Прграмма лояльности</Link>
            </li>
          </ul>
        </div>
        <div className={clsx(styles.content_block, styles.content_map)}>
          <iframe
            src='https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;um=constructor%3A41a37279c941145964e5a94d21e1ff440d7bbc62826dfd1af806833110176420'
            frameBorder='0'
            allowFullScreen={true}
            width='100%'
            height='100%'
            title='map'
          ></iframe>
        </div>
      </div>
      <div className={styles.copy}>
        <span>&copy; 2021-{new Date().getFullYear()}</span>
        <p>Все права защищены.</p>
      </div>
    </footer>
  )
}

export default Footer
