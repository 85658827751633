import React from 'react'

// import pdfIcon from '../../assets/img/pdf.svg'
import mainImg from '../../assets/img/menus/main.jpg'
import setImg from '../../assets/img/menus/set.jpg'
import postImg from '../../assets/img/menus/post.jpg'
import breakfastImg from '../../assets/img/menus/breakfast.jpg'
import healthImg from '../../assets/img/menus/health-food.jpg'

import styles from './menus.module.scss'

const menus = [
  {
    title: 'Основное меню/Бар',
    link: 'main-menu.pdf',
    img: mainImg,
  },
  {
    title: 'Завтраки',
    link: 'breakfast-menu.pdf',
    img: breakfastImg,
  },
  {
    title: 'Постное меню',
    link: 'postnoe-menu.pdf',
    img: postImg,
  },
  {
    title: 'Правильное питание',
    link: 'health-food.pdf',
    img: healthImg,
  },
  {
    title: 'Сет-Меню по будням с 12:00 до 15:00',
    link: 'set-menu.pdf',
    img: setImg,
  },
]

const MenusPage = () => {
  return (
    <section className={styles.menus}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Меню</h2>
        </div>
        <div className={styles.items}>
          {menus.map((item) => (
            <a
              href={item.link}
              key={item.link}
              target='_blank'
              rel='noindex nofollow noreferrer'
              className={styles.item}
            >
              <div className={styles.item_bg}>
                <img
                  src={item.img}
                  alt={item.title}
                />
              </div>
              <div className={styles.item_content}>
                {/* <div className={styles.item_icon}>
                  <img
                    src={pdfIcon}
                    alt='pdf icon'
                  />
                </div> */}
                <span className={styles.item_title}>{item.title}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default MenusPage
